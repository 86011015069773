import _ from 'lodash';
import Moment from 'moment';
import { createSelector } from 'reselect';
import { extendMoment } from 'moment-range';

import { getDimensions } from 'common/constants/dimensions';
import CREATIVE_TYPES from 'common/constants/creative-types';
import IAB_CATEGORIES from 'common/constants/iab-categories';
import { isInternalUser } from 'states/profile/business-rules';
import { getMetrics, createMetricMenu, findMetrics } from './constants';
import { Transformer } from '../../campaigns/report/report-explore/reducers/metric-selector';
import flags from 'containers/flags/service';

const moment = extendMoment(Moment);
const ADDICTIVE_ORG_ID = '53271100d26f9a2f917ed290';

function getDimensionLookup() {
    const DIMENSION_LOOKUP = {};
    _.each(getDimensions(), dimension => {
        DIMENSION_LOOKUP[dimension.name] = dimension;
    });

    return DIMENSION_LOOKUP;
}

const CREATIVE_TYPES_LOOKUP = {};
_.each(CREATIVE_TYPES, type => {
    CREATIVE_TYPES_LOOKUP[type.value] = type.label;
});

const ownOrgSelector = storeState => _.get(storeState, 'businessReportEditor.ownOrg');
const clientOrgsSelector = storeState => _.get(storeState, 'businessReportEditor.clientOrgs');
const nameDraftSelector = storeState => _.get(storeState, 'businessReportEditor.draft.name');
const campaignStatusDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.campaignStatus');
const campaignsDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.campaigns');
const clientDraftSelector = storeState => _.get(storeState, 'businessReportEditor.draft.client');
const creativeTypeDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.creativeType');
const dateRangeDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.dateRange');
const adEndDateFilterSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.adEndDate');
const organizationFilterSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.organizationFilter');
const dimensionsDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.dimensions');
const iabCategoryDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.iabCategory');
const timezoneDraftSelector = storeState =>
    _.get(storeState, 'businessReportEditor.draft.timezone');
const draftSelector = storeState => _.get(storeState, 'businessReportEditor.draft');
const reportCampaignsLoaderLoadingSelector = storeState =>
    _.get(storeState, 'reportCampaignsLoader.isLoading');
const organiztionForFilteringSelector = storeState =>
    _.get(storeState, 'businessReportEditor.organizationsForFiltering');
const searchFiltersSelector = storeState => _.get(storeState, 'businessReportEditor.searchFilter');
const selectAllSelector = storeState => _.get(storeState, 'businessReportEditor.selectAll');
const campaignsSelector = storeState => _.get(storeState, 'businessReportEditor.campaigns');
export const campaignsSummarySelector = storeState =>
    _.get(storeState, 'businessReportEditor.campaignsSummary');
export const selectedSubeventsSelector = storeState =>
    _.get(storeState, 'businessReportEditor.selectedSubevents');

const generateReportCampaignsSelector = campaignSelectorToUse =>
    createSelector(
        campaignSelectorToUse,
        campaignsNew => {
            const mapping = {};

            _.each(campaignsNew, campaign => {
                mapping[campaign.id] = campaign;
            });

            return mapping;
        }
    );

const reportCampaignsSelector = generateReportCampaignsSelector(campaignsSelector);

const exportMetaSelector = createSelector(
    clientDraftSelector,
    dateRangeDraftSelector,
    campaignsDraftSelector,
    timezoneDraftSelector,
    creativeTypeDraftSelector,
    dimensionsDraftSelector,
    iabCategoryDraftSelector,
    campaignStatusDraftSelector,
    reportCampaignsSelector,
    nameDraftSelector,
    ownOrgSelector,
    (
        client,
        dateRange,
        campaigns,
        timezone,
        creativeType,
        dimensions,
        iabCategory,
        campaignStatus,
        campaignResourcesOld,
        name,
        ownOrg
    ) => {
        const clientLabel = ownOrg && ownOrg.name;
        let campaignsLabel;
        if (campaigns.ids.length === 0) {
            campaignsLabel = 'All Campaigns';
        } else {
            const mode = _.capitalize(campaigns.mode);
            const campaignNames = _.map(campaigns.ids, campaignId => {
                return `#${campaignId} ${_.get(
                    campaignResourcesOld,
                    `${campaignId}.name`,
                    campaignId
                )}`;
            }).join(', ');

            campaignsLabel = `${mode}: ${campaignNames}`;
        }

        let dimensionsLabel;
        if (dimensions.length === 0) {
            dimensionsLabel = 'None';
        } else {
            dimensionsLabel = _.map(dimensions, dimension => {
                return _.get(getDimensionLookup(), `${dimension}.label`, dimension);
            }).join(', ');
        }

        const creativeTypeLabel = _.get(CREATIVE_TYPES_LOOKUP, creativeType, creativeType);
        const iabCategoryLabel = _.get(IAB_CATEGORIES, iabCategory, iabCategory);

        const startDate = moment.tz(dateRange.start, timezone).format('LL');

        const endDate = moment.tz(dateRange.end, timezone).format('LL');

        return {
            fileName: name || 'Untitled',
            timezone: timezone,
            campaigns: campaignsLabel,
            campaignStatus: _.capitalize(campaignStatus),
            creativeType: creativeTypeLabel,
            iabCategory: iabCategoryLabel,
            client: clientLabel,
            dimensions: dimensionsLabel,
            dateRange: `${startDate} - ${endDate}`,
            exportDate: moment.tz(timezone).format('LL'),
        };
    }
);

export const organizationForFilteringOptionSelector = createSelector(
    organiztionForFilteringSelector,
    organizationForFiltering => {
        const addictive = _.find(organizationForFiltering, org => org.id === ADDICTIVE_ORG_ID);
        let finalOrganizations = [...organizationForFiltering];
        if (addictive) {
            const organizationsWithoutAddictive = _.remove(
                organizationForFiltering,
                org => org.id !== ADDICTIVE_ORG_ID
            );
            finalOrganizations = _.concat([addictive], organizationsWithoutAddictive);
        }

        return _.map(finalOrganizations, org => {
            return { label: org.name, value: org.id };
        });
    }
);
function addTypeAndDimension(field, dimension) {
    return {
        ...field,
        dimension: `${dimension}_custom_field`,
        type: `${field.name.split(' ').join('_')}_${dimension}`,
    };
}

const customFieldsSeletor = createSelector(
    ownOrgSelector,
    ownOrg => {
        const ownOrganization = ownOrg;

        if (ownOrganization) {
            const campaignCustomFields = _.map(ownOrganization.custom_fields, field =>
                addTypeAndDimension(field, 'campaign')
            );

            const adCustomFields = _.map(ownOrganization.custom_fields, field =>
                addTypeAndDimension(field, 'ad')
            );

            return [...campaignCustomFields, ...adCustomFields];
        }

        return [];
    }
);

const attributeOptionsSelector = createSelector(
    customFieldsSeletor,
    customFields => {
        let baseOptions = [];

        const newCustomFields = _.map(customFields, customField => {
            // value should be type so that the backend create a map in order to create a report
            // urban/app/repositories/StandardStatsRepository-v2/configs/create-attribute-config.js
            const value = `cf_${customField.type}_attr`;

            return {
                label: customField.name,
                value,
                category: 'custom',
                dimension: customField.dimension,
                type: customField.type,
            };
        });
        baseOptions = baseOptions.concat(newCustomFields);

        baseOptions = baseOptions.concat([
            {
                label: 'Start Date',
                value: 'ad_start_attr',
            },
            {
                label: 'End Date',
                value: 'ad_end_attr',
            },
        ]);

        baseOptions = baseOptions.concat([
            {
                label: 'ID',
                value: 'campaign_id_attr',
            },
            {
                label: 'ID',
                value: 'ad_id_attr',
            },
            {
                label: 'ID',
                value: 'creative_id_attr',
            },
            {
                label: 'Name',
                value: 'campaign_name_attr',
            },
            {
                label: 'Name',
                value: 'ad_name_attr',
            },
            {
                label: 'Name',
                value: 'creative_name_attr',
            },
            { label: 'Billing Rate', value: 'billing_rate_attr' },
            {
                label: 'Notes',
                value: 'ad_notes_attr',
            },
            {
                label: 'Platform',
                value: 'ad_platform_attr',
            },
            {
                label: 'Billable Impressions',
                value: 'billable_impressions_attr',
            },
            {
                label: 'Non-billable Impressions',
                value: 'added_value_impressions_attr',
            },
            {
                label: 'Billable Clicks',
                value: 'billable_clicks_attr',
            },
            {
                label: 'Non-billable Clicks',
                value: 'added_value_clicks_attr',
            },
            {
                label: 'Currency',
                value: 'campaign_currency_attr',
            },
            {
                label: 'Partner',
                value: 'partner_attr',
            },
            {
                label: 'Advertiser',
                value: 'advertiser_attr',
            },
            {
                label: 'Advertiser Domain',
                value: 'advertiser_domain_attr',
            },
            {
                label: 'Advertiser Category',
                value: 'advertiser_category_attr',
            },
            {
                label: 'Sales Rep',
                value: 'sales_rep_attr',
            },
            {
                label: 'Audience Type',
                value: 'audience_segment_type_attr',
            },
        ]);

        baseOptions = baseOptions.concat([
            {
                label: 'Format',
                value: 'creative_format_attr',
            },
            {
                label: 'Type',
                value: 'creative_type_attr',
            },
            {
                label: 'Channel',
                value: 'creative_channel_attr',
            },
            {
                label: 'Size',
                value: 'creative_size_attr',
            },
            {
                label: 'Creative/Tracking Vendors',
                value: 'creative_vendors_attr',
            },
        ]);

        if (isInternalUser()) {
            baseOptions = baseOptions.concat([
                {
                    label: 'Lifetime Impressions',
                    value: 'lifetime_impressions',
                    description: 'requires ad dimension',
                },
                {
                    label: 'Lifetime Clicks',
                    value: 'lifetime_clicks',
                    description: 'requires ad dimension',
                },
                {
                    label: 'Lifetime Total Cost',
                    value: 'lifetime_spend',
                    description: 'requires ad dimension',
                },
                {
                    label: 'Lifetime Revenue',
                    value: 'lifetime_billings_local',
                    description: 'requires ad dimension',
                },
                {
                    label: 'Organization Type',
                    value: 'organization_type_attr',
                    description: 'requires organization dimension',
                },
            ]);

            if(flags.isEnabled('ef_106_audience_id_dimension')) {
                baseOptions = baseOptions.concat([
                    {
                        label: 'Audience ID',
                        value: 'audience_segment_id_attr',
                    },
                ]);
            }
        }

        baseOptions = baseOptions.concat([
            {
                label: 'Primary Custom Dimension',
                value: 'primary_ad_group_attr',
            },
        ]);

        return baseOptions;
    }
);
const clientOptionsSelector = createSelector(
    ownOrgSelector,
    clientOrgsSelector,
    (ownOrg, clientOrgs) => {
        const childOrganizations = _(clientOrgs)
            .map(org => org)
            .filter(org => org)
            .value();

        return _(childOrganizations)
            .filter(x => x)
            .map(org => ({
                label: org.name,
                value: org.id,
            }))
            .sortBy('label')
            .value();
    }
);

// build campaignOptions with multiple-filters from fetchCampaigns() graphQL
const generateCampaignOptionsSelector = campaignSelectorToUse =>
    createSelector(
        clientDraftSelector,
        iabCategoryDraftSelector,
        campaignSelectorToUse,
        campaignsDraftSelector,
        draftSelector,
        (client, iabCategory, campaignsResource, campaignFilter, draft) => {
            const campaignLookup = {};
            _.each(campaignFilter.ids, id => (campaignLookup[id] = true));

            const priviledgedCampaigns = {};
            function commonFilter(fn) {
                return campaign => {
                    const filter = fn(campaign);

                    if (campaignLookup[campaign.id]) {
                        // Mark campaigns which would have been removed, but are allowed
                        // to stay because it was previously selected
                        if (filter === false) {
                            priviledgedCampaigns[campaign.id] = true;
                        }
                        return true;
                    }

                    return filter;
                };
            }
            const campaignOptions = _(campaignsResource)
                .filter(campaign => campaign)
                .filter(campaign => {
                    let isAnyAdWithinDates;
                    let isAnyAdWithinAdEndRange;

                    if (draft.dateRange.isEnabled) {
                        const { start, end } = getStartAndEndDate(draft.dateRange);
                        const dateRange = moment.range(start, end);
                        isAnyAdWithinDates = _.some(campaign.ads, ad => {
                            const adRange = moment.range(ad.start, ad.end);
                            return dateRange.overlaps(adRange);
                        });
                    }
                    if (draft.adEndDate.isEnabled) {
                        const { start, end } = getStartAndEndDate(draft.adEndDate);
                        const adEndDateRange = moment.range(start, end);
                        isAnyAdWithinAdEndRange = _.some(campaign.ads, ad => {
                            const adEnd = moment(ad.end);
                            const isWithin = adEnd.within(adEndDateRange);
                            return isWithin;
                        });
                    }

                    if (draft.dateRange.isEnabled && draft.adEndDate.isEnabled) {
                        return isAnyAdWithinDates && isAnyAdWithinAdEndRange;
                    } else if (draft.dateRange.isEnabled) {
                        return isAnyAdWithinDates;
                    } else if (draft.adEndDate.isEnabled) {
                        return isAnyAdWithinAdEndRange;
                    }
                    return true;
                })
                .values(x => x)
                .filter(
                    commonFilter(campaign => {
                        // By IAB category
                        if (!iabCategory) {
                            return true;
                        }

                        return _.includes(campaign.iab_categories, iabCategory);
                    })
                )
                .filter(
                    commonFilter(campaign => {
                        if (!client) {
                            return true;
                        }

                        const clientsForThisCampaign = _.map(
                            campaign.clients,
                            client => client.organization
                        );

                        return _.includes(clientsForThisCampaign, client);
                    })
                )
                .map(campaign => {
                    let strikeThrough;
                    if (priviledgedCampaigns[campaign.id]) {
                        strikeThrough = true;
                    }

                    return {
                        label: `#${campaign.id} ${campaign.name}`,
                        value: campaign.id.toString(),
                        strikeThrough,
                        mayContainBadAudienceReportData: campaign.mayContainBadAudienceReportData,
                    };
                })
                .orderBy('value', 'desc')
                .value();
            return campaignOptions;
        }
    );
const campaignOptionsSelector = generateCampaignOptionsSelector(reportCampaignsSelector);

// campaignOptionsSelector and old campaign list from resource
const engagementOptionsSelector2 = createSelector(
    reportCampaignsSelector,
    campaignsDraftSelector,
    campaignOptionsSelector,
    campaignsDraftSelector,
    (campaignResource, campaigns, campaignOptions, draft) => {
        let filteredCampaignIds;
        if (campaigns.ids.length > 0) {
            if (draft.mode === 'include') {
                filteredCampaignIds = campaigns.ids;
            } else {
                filteredCampaignIds = _.map(campaignOptions, option => option.value).filter(
                    campaignId => !_.includes(campaigns.ids, campaignId)
                );
            }
        } else {
            filteredCampaignIds = _.map(campaignOptions, option => option.value);
        }

        const overallEngagement = {
            value: 'overall_engagements',
            label: 'Overall Engagements',
        };

        const beaconOptions = _(filteredCampaignIds)
            .map(campaignId => campaignResource[campaignId])
            .filter(c => c)
            .map(campaign => campaign.beacons || [])
            .flatten()
            .uniqBy('name')
            .orderBy('label')
            .map(beacon => {
                return { value: `event_${beacon.name}`, label: beacon.label };
            })
            .value();

        // Add overall engagements as the first option
        return [].concat(overallEngagement, beaconOptions);
    }
);

const conversionsOptionsSelector = createSelector(
    reportCampaignsSelector,
    campaignsDraftSelector,
    (campaignResource, campaigns) => {
        let filteredCampaignIds;
        if (campaigns.ids.length > 0) {
            filteredCampaignIds = campaigns.ids;
        } else {
            filteredCampaignIds = Object.keys(campaignResource);
        }

        const conversionsOptions = _(filteredCampaignIds)
            .map(campaignId => {
                return campaignResource[campaignId];
            })
            .filter(c => c)
            .map(campaign => campaign.conversions || [])
            .flatten()
            .uniqBy('event_name')
            .orderBy('reporting_name')
            .map(conversion => {
                if (flags.isEnabled('en_4598_new_report_conversion_columns')) {
                    return Transformer.createConversionComponents([conversion], [], {});
                }

                let conversionsMetrics = [];
                if (conversion.use_click_through) {
                    conversionsMetrics.push({
                        value: `conv_click_${conversion.event_name}`,
                        label: `${conversion.reporting_name} (Campaign Pixel Click Through)`,
                    });
                }
                if (conversion.use_view_through) {
                    conversionsMetrics.push({
                        value: `conv_imp_${conversion.event_name}`,
                        label: `${conversion.reporting_name} (Campaign Pixel View Through)`,
                    });
                }
                return conversionsMetrics;
            })
            .flatten()
            .value();

        const advertiserConversionsOptions = _(filteredCampaignIds)
            .map(campaignId => {
                return campaignResource[campaignId];
            })
            .filter(c => c)
            .map(campaign => campaign.advertiserConversions || [])
            .flatten()
            .uniqBy('event_name')
            .orderBy('reporting_name')
            .map(advertiserConversion => {
                if (flags.isEnabled('en_4598_new_report_conversion_columns')) {
                    return Transformer.createConversionComponents([], [advertiserConversion], {});
                }

                let advertiserConversionsMetrics = [];
                if (advertiserConversion.use_click_through) {
                    advertiserConversionsMetrics.push({
                        value: `conv_click_adv_${advertiserConversion.event_name}`,
                        label: `${
                            advertiserConversion.reporting_name
                        } (Advertiser Pixel Click Through)`,
                    });
                }
                if (advertiserConversion.use_view_through) {
                    advertiserConversionsMetrics.push({
                        value: `conv_imp_adv_${advertiserConversion.event_name}`,
                        label: `${
                            advertiserConversion.reporting_name
                        } (Advertiser Pixel View Through)`,
                    });
                }
                return advertiserConversionsMetrics;
            })
            .flatten()
            .value();

        // Add conversions as the first option
        return _.uniqWith(
            [].concat(conversionsOptions, advertiserConversionsOptions),
            (conv1, conv2) => {
                if (!conv1.metricType) {
                    // We only do this for the new report conversion columns
                    return false;
                }

                return (
                    conv1.metricType === conv2.metricType &&
                    conv1.conversionName === conv2.conversionName &&
                    conv1.presentationName === conv2.presentationName
                );
            }
        );
    }
);

export const getFilters = createSelector(
    campaignsDraftSelector,
    dateRangeDraftSelector,
    adEndDateFilterSelector,
    organizationFilterSelector,
    (campaignFilter, dateFilter, adEndDateFilter, organizationFilter) => {
        return { campaignFilter, dateFilter, adEndDateFilter, organizationFilter };
    }
);

const mapping = {
    checked: {
        text: 'Unselect Filtered',
        noText: 'Select None',
    },
    unchecked: {
        text: 'Select Filtered',
        noText: 'Select All',
    },
};

export const getSelectedCampaigns = createSelector(
    campaignsDraftSelector,
    campaignFilter => {
        const selections = {};
        if (campaignFilter) {
            _.forEach(campaignFilter.ids, id => (selections[id] = true));
        }

        return selections;
    }
);

export const getSelectAllTitle = createSelector(
    searchFiltersSelector,
    selectAllSelector,
    (searchFilter, selectAll) => {
        const selectAllStatus = selectAll ? 'checked' : 'unchecked';
        const filterStatus = searchFilter.length > 0 ? 'text' : 'noText';
        const selectAllTitle = mapping[selectAllStatus][filterStatus];
        return selectAllTitle;
    }
);

export const getFilteredCampaigns = createSelector(
    searchFiltersSelector,
    campaignOptionsSelector,
    (searchFilter, campaignOptions) => {
        const filteredCampaigns = campaignOptions.filter(
            campaign => campaign.label.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
        );
        return filteredCampaigns;
    }
);

export function getStartAndEndDate(filter) {
    let start, end;
    if (filter.type === 'preset' || filter.type === 'custom') {
        start = filter.start;
        end = filter.end;
    } else if (filter.type === 'dynamic') {
        if (filter.from === 'day_before_report_generation_date') {
            end = moment()
                .subtract(1, 'day')
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
        } else {
            end = moment()
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
        }

        if (filter.lastFrame === 'calendar_months') {
            start = start = moment(end)
                .subtract(filter.last - 1, 'months')
                .startOf('month')
                .format('YYYY-MM-DDTHH:mm:ss');
        } else {
            start = moment(end)
                .subtract(filter.last, filter.lastFrame)
                .add(1, 'days')
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
        }
    }
    return { start, end };
}

export const getAllMetrics = createSelector(
    engagementOptionsSelector2,
    conversionsOptionsSelector,
    (state, isInternalUser) => isInternalUser,
    (engagementOptions, conversionOptions, isInternalUser) => {
        const metrics = getMetrics();

        const [internalMetrics, nonInternalMetrics] = _.partition(metrics, {
            category: 'Internal Metrics',
        });

        const engagements = _.map(engagementOptions, option => ({
            category: 'Engagements',
            metricLabel: option.label,
            metricKey: option.value,
        }));

        const conversions = _.map(conversionOptions, option => ({
            ...option,
            category: 'Conversions',
            metricLabel: flags.isEnabled('en_4598_new_report_conversion_columns')
                ? option.presentationName
                : option.label,
            metricKey: flags.isEnabled('en_4598_new_report_conversion_columns')
                ? option.metricType
                : option.value,
        }));

        return [
            ...nonInternalMetrics,
            ...engagements,
            ...conversions,
            ...(isInternalUser ? internalMetrics : []),
        ];
    }
);

export const getMetricMenu = createSelector(
    getAllMetrics,
    allMetrics => {
        return createMetricMenu(allMetrics);
    }
);

export const getSelectedMetrics = createSelector(
    draftSelector,
    getAllMetrics,
    (draft, allMetrics) => {
        const selectedMetricsKeys = _(draft.metrics)
            .concat(draft.engagements)
            .concat(draft.conversions)
            .filter(metric => metric)
            .value();

        const metrics = findMetrics(allMetrics, selectedMetricsKeys);
        return createMetricMenu(metrics);
    }
);

export default function selector(storeState) {
    const ownOrganization = _.get(storeState, `businessReportEditor.ownOrg`);
    const isEditorOpen = _.get(storeState, `businessReportEditor.isEditorOpen`);
    const businessReportId = _.get(storeState, `businessReportEditor.businessReportId`);
    return {
        profile: storeState.profile,
        editorState: _.get(storeState, 'businessReportEditor'),
        campaignOptions: campaignOptionsSelector(storeState),
        clientOptions: clientOptionsSelector(storeState),
        engagementOptions: engagementOptionsSelector2(storeState),
        conversionsOptions: conversionsOptionsSelector(storeState),
        dictionary: _.get(storeState, 'dictionary', {}),
        exportMeta: exportMetaSelector(storeState),
        isReportCampaignsLoaderLoading: reportCampaignsLoaderLoadingSelector(storeState),
        attributeOptions: attributeOptionsSelector(storeState),
        ownOrganization,
        isEditorOpen,
        businessReportId,
        filters: getFilters(storeState),
        organiztionForFilteringOptions: organizationForFilteringOptionSelector(storeState),
        selections: getSelectedCampaigns(storeState),
        selectAllTitle: getSelectAllTitle(storeState),
        filteredCampaigns: getFilteredCampaigns(storeState),
    };
}
