import _ from 'lodash';

import AdPresetActions from 'states/resources/ad-presets/actions';
import { createHttp } from 'utils/http';
import API from './services/api';
import {
    getDaysRunning,
    isTacticsEnabled,
    calculateBidPriceFromAd,
} from 'states/resources/ads/business-logic';
import geoLayersSearch from 'services/gis/geo-layers-search';
import { CreativeRotationMethodMapping } from 'states/resources/ads/business-logic';

const AdNew = {
    open(getInitialDraft, campaignId, adId, useTactics) {
        return async (dispatch, getState) => {
            dispatch({
                type: 'AD_FORM__INIT_STATE',
                payload: { campaignId },
            });

            dispatch({
                type: 'AD_FORM__OPEN',
                payload: { campaignId },
            });
            const orgId = _.get(getState(), 'profile.organizationId');
            const [payload, data] = await Promise.all([
                getInitialDraft(campaignId, adId, useTactics),
                fetchAdFormData(Number(campaignId), orgId),
                dispatch(AdPresetActions.getAll(orgId)),
            ]);

            const audiences = data.audienceSegments;
            const creatives = data.campaign.creatives;
            const conversions = data.campaign.conversions;
            const carriersISPsVersion = data.campaign.carriersISPsVersion;
            const applists = data.applists;
            const flexSegments = data.flexSegments;
            const orgLevelBlacklists = data.organization.blacklistedAppsAndSites;
            const advertiser = _.first(
                _.intersectionWith(
                    data.organizations,
                    data.campaign.clients,
                    (a, b) => a.id === b.organization && a.client_type === 'advertiser'
                )
            );
            const advertiserLevelBlacklists = _.get(advertiser, 'blacklistedAppsAndSites', []);
            const advertiserLevelWhiteLists = _.get(advertiser, 'whitelistedAppsAndSites', []);
            const defaultBlacklists = _.union(orgLevelBlacklists, advertiserLevelBlacklists);
            const defaultWhitelists = advertiserLevelWhiteLists;

            dispatch({
                type: 'AD_FORM__INIT',
                payload: {
                    ...payload,
                    useTactics,
                    audiences,
                    creatives,
                    conversions,
                    applists,
                    defaultBlacklists,
                    defaultWhitelists,
                    techFee: data.campaign.tech_fee,
                    dealsByOrganization: data.dealsByOrganization,
                    ftaLocationLists: data.ftaLocationLists,
                    campaignBudgetAllocationMethod: data.campaign.budget_allocation_method,
                    campaignCarriersISPsVersion: carriersISPsVersion,
                    isCrossPlatformCampaign: data.campaign.isCrossPlatformCampaign,
                    flexSegments,
                    advertiser,
                },
            });

            dispatch(this.updatePrediction());
        };
    },

    updatePrediction() {
        return async (dispatch, getState) => {
            const inventoryPredictionLoading = _.get(
                getState(),
                `adForm.inventoryPredictionLoading`
            );
            const isCrossPlatformCampaign = _.get(
                getState(),
                'adForm.form.isCrossPlatformCampaign'
            );
            if (isCrossPlatformCampaign) {
                return;
            }
            if (inventoryPredictionLoading) {
                return;
            }

            dispatch({
                type: 'AD_FORM__GENERATE_PREDICTION',
                payload: {},
            });

            const formData = _.get(getState(), `adForm.form.draft`);
            const audiences = _.get(getState(), 'adForm.form.audiencesMapping', {});

            const techFee = _.get(getState(), `adForm.form.tech_fee`, 0);

            const bidRate = calculateBidPriceFromAd(formData, techFee);

            const isUsingNonPdsIncludeSegments = _(formData.audiences)
                .map(audienceId => audiences[audienceId])
                .some(audience => audience.source_type.indexOf('pds_') === -1);

            const isUsingNonPdsExcludeSegments = _(formData.audience_exclude)
                .map(audienceId => audiences[audienceId])
                .some(audience => audience.source_type.indexOf('pds_') === -1);

            let audienceDraft = formData;

            const customSegmentsTypes = ['upload', 'adobe', 'campaign', 'ad', 'pds_custom'];

            const audiencesMapping = _.get(getState(), `adForm.form.audiencesMapping`);

            const audienceIds = audienceDraft.audiences;

            const audienceSourceTypes = _.map(
                audienceIds,
                audienceId => audiencesMapping[audienceId].source_type
            );

            let hasOnlyCustomAudiences = audienceIds.length > 0 ? true : false;

            _.forEach(audienceSourceTypes, function(audienceType) {
                if (_.includes(customSegmentsTypes, audienceType) === false) {
                    hasOnlyCustomAudiences = false;
                }
            });

            let inventoryPredictionError;

            if (isCrossPlatformCampaign && _.includes(formData.platforms, 'mweb')) {
                inventoryPredictionError = 'Forecast not supported for Mobile Web.';
            } else if (isCrossPlatformCampaign && _.includes(formData.platforms, 'mweb')) {
                inventoryPredictionError = 'Forecast not supported for Desktop.';
            } else if (formData.platform === 'mweb') {
                inventoryPredictionError = 'Forecast not supported for Mobile Web.';
            } else if (formData.platform === 'desktop') {
                inventoryPredictionError = 'Forecast not supported for Desktop.';
            } else if (!formData.start || !formData.end) {
                inventoryPredictionError = 'Set start and end dates to see forecast.';
            } else if (formData.rotation_rules.mode !== CreativeRotationMethodMapping.Single) {
                inventoryPredictionError = 'Forecast not supported for rotated creatives.';
            } else if (formData.creative.length === 0) {
                inventoryPredictionError = 'Select a creative to see forecast';
            } else if (formData.geotargets.length === 0) {
                inventoryPredictionError = 'Select a country to see forecast';
            } else if (formData.geotargets.length > 1 || formData.geotargets[0].country !== 'CA') {
                inventoryPredictionError = 'Forecast not supported fore regions outside Canada';
            } else if (isUsingNonPdsIncludeSegments || isUsingNonPdsExcludeSegments) {
                inventoryPredictionError = 'Forecast not supported for custom segments';
            } else if (!formData.max_cpm_rate_local) {
                inventoryPredictionError = 'Set Total Bid Price to see forecast.';
            } else if (formData.fcaps.interval_unit === 'hours') {
                inventoryPredictionError = 'Forecast not supported for hourly frequency cap.';
            } else if (formData.fcaps.interval_unit === 'weeks') {
                inventoryPredictionError = 'Forecast not supported for weekly frequency cap.';
            } else if (formData.fcaps.interval_unit === 'ad') {
                inventoryPredictionError =
                    'Forecast not supported for frequency caps based on ad lifetime.';
            } else if (hasOnlyCustomAudiences) {
                inventoryPredictionError = 'Forecast not supported for custom segments';
            }

            if (inventoryPredictionError) {
                return dispatch({
                    type: 'AD_FORM__GENERATE_PREDICTION__FAILED',
                    payload: {
                        error: inventoryPredictionError,
                    },
                });
            }

            let creativeSize = _.get(
                getState(),
                `resources.creatives.${formData.creative[0]}.attr.size`
            );
            let creativeFormat = _.get(
                getState(),
                `resources.creatives.${formData.creative[0]}.attr.format`
            );

            const requestPayload = {
                geo_cregion_include: _(formData.geotargets)
                    .map(target => target.include)
                    .flatten()
                    .filter(includedTarget => !includedTarget.city)
                    .map(includedTarget => includedTarget.region)
                    .value(),
                geo_cregion_exclude: _(formData.geotargets)
                    .map(target => target.exclude)
                    .flatten()
                    .filter(includedTarget => !includedTarget.city)
                    .map(includedTarget => includedTarget.region)
                    .value(),
                geo_city_include: _(formData.geotargets)
                    .map(target => target.include)
                    .flatten()
                    .filter(includedTarget => includedTarget.city)
                    .map(includedTarget => includedTarget.city)
                    .value(),
                geo_city_exclude: _(formData.geotargets)
                    .map(target => target.exclude)
                    .flatten()
                    .filter(includedTarget => includedTarget.city)
                    .map(includedTarget => includedTarget.city)
                    .value(),
                creative_size: creativeSize,
                creative_format: creativeFormat,
                age_groups: formData.target_age_groups,
                genders: formData.target_genders,
                device_os: formData.target_device_os,
                frequency_cap: formData.fcaps.imp,
                pattison_billboards: [],
                bid_rate: bidRate,
            };

            requestPayload.tactics_generators = formData.tactics_generators;

            try {
                // request 1
                // ctr on will only include averge pool of inventory
                const requestMin = await API.generatePrediction({
                    ...requestPayload,
                    ctr: [1],
                });

                // request 2
                // ctr off will include all possible inventory
                const requestMax = await API.generatePrediction({
                    ...requestPayload,
                    ctr: [0, 1],
                });

                const daysRunning = getDaysRunning(formData);

                dispatch({
                    type: 'AD_FORM__GENERATE_PREDICTION__COMPLETE_EF_51',
                    payload: {
                        dailyUniqueUsers: requestMin.dailyUniqueUsers,
                        dailyAvailableImpressions:
                            requestMin.dailyAvailableImpressions * daysRunning,

                        totalUniqueUsersMin: requestMin.dailyUniqueUsers,
                        totalAvailableImpressionsMin:
                            requestMin.dailyAvailableImpressions * daysRunning,

                        totalUniqueUsersMax: requestMax.dailyUniqueUsers,
                        totalAvailableImpressionsMax:
                            requestMax.dailyAvailableImpressions * daysRunning,
                    },
                });
            } catch (err) {
                let userFacingError;

                if (_.get(err, 'body.0') === 'SyntaxError') {
                    userFacingError = err.body[1];
                } else {
                    userFacingError = 'An unknown error occured.';
                }

                dispatch({
                    type: 'AD_FORM__GENERATE_PREDICTION__FAILED',
                    payload: {
                        error: userFacingError,
                    },
                });
            }
        };
    },

    updateSubmoduleDraft({ errors, draft }) {
        return {
            type: 'AD_FORM_SUBMODULE__DRAFT_UPDATE',
            payload: {
                draft,
                errors,
            },
        };
    },

    updateDraft(updatedData, campaignId, adId, campaignBillingConfig) {
        return {
            type: 'AD_FORM__UPDATE',
            payload: { formData: updatedData, campaignId, adId, campaignBillingConfig },
        };
    },

    updateModuleDraft({ draft, errorKey, errors }) {
        if (!errorKey) {
            throw new Error('errorKey is required when using the module draft system');
        }
        return {
            type: 'AD_FORM__MODULE__UPDATE',
            payload: {
                errorKey,
                draft,
                errors,
            },
        };
    },

    updateAudiences({ includedItems, excludedItems, geo_targeting_settings }) {
        return {
            type: 'AD_FORM__AUDIENCES__UPDATE',
            payload: {
                includedItems,
                excludedItems,
                geo_targeting_settings,
            },
        };
    },

    showErrors(campaignId) {
        return {
            type: 'AD_FORM__SHOW_ERRORS',
            payload: { campaignId },
        };
    },
    openAdPresetModal(isAdPresetModalOpen, campaignId) {
        return {
            type: 'AD_FORM_OPEN_AD_PRESET_MODAL',
            payload: { isAdPresetModalOpen, campaignId },
        };
    },

    closeAdPresetModal(isAdPresetModalOpen, campaignId) {
        return {
            type: 'AD_FORM_CLOSE_AD_PRESET_MODAL',
            payload: { isAdPresetModalOpen, campaignId },
        };
    },

    setAdPreset(adpreset, campaignId) {
        return {
            type: 'AD_FORM_SET_AD_PRESET',
            payload: {
                adpreset: {
                    ...adpreset,
                },
                campaignId,
            },
        };
    },

    validateDraft({
        onValidForm,
        campaignId,
        overrideDealPriceWarning,
        hasCrossPlatformCampaignWarning,
    }) {
        return (dispatch, getState) => {
            const errors = _.get(getState(), `adForm.form.errors`, []);
            if (errors.length !== 0) {
                return dispatch(AdNew.showErrors());
            }

            const errorMapping = _.get(getState(), `adForm.form.errorMapping`, {});
            if (_.keys(errorMapping).length !== 0) {
                return dispatch(AdNew.showErrors());
            }

            const draft = _.get(getState(), `adForm.form.draft`);
            if (isTacticsEnabled(draft.tactics_enabled, campaignId)) {
                const moduleErrors = _.get(getState(), `adForm.form.moduleErrors`, {});
                const hasModuleErrors = _(moduleErrors)
                    .values()
                    .some(x => x === true);

                if (hasModuleErrors) {
                    return dispatch(AdNew.showErrors());
                }
            }

            if (hasCrossPlatformCampaignWarning || overrideDealPriceWarning.hasWarning) {
                return dispatch({
                    type: 'AD_FORM__OPEN_WARNING_CONFIRMATION_MODAL',
                    payload: {},
                });
            }

            return onValidForm();
        };
    },

    submitDraft(campaignId) {
        if (!campaignId) {
            throw new Error('New Ad data not found');
        }
        return (dispatch, getState) => {
            const draft = _.get(getState(), `adForm.form.draft`);

            dispatch({
                type: 'AD_FORM__SUBMIT',
                payload: { campaignId },
            });

            if (draft.use_front_load_pacing) {
                draft.max_daily_impressions = 0;
                draft.max_daily_clicks = 0;
                draft.max_daily_billings = 0;
                draft.max_daily_spend_local = 0;
            }
            return Promise.resolve(draft);
        };
    },

    showServerErrors(error) {
        return {
            type: 'AD_FORM_SUBMIT_SERVER_ERROR__SHOW',
            error,
            payload: {},
        };
    },

    setStartToASAP(campaignId, adId) {
        return {
            type: 'AD_FORM__SET_START_ASAP',
            payload: { campaignId, adId },
        };
    },

    showGeoEntityInMap(campaignId, adId, entityType, entityId) {
        return dispatch => {
            dispatch({
                type: 'AD_FORM__SHOW_GEO_ENTITY_IN_MAP',
                payload: { campaignId, adId, entityType, entityId },
            });
        };
    },

    refreshGeoEntityInMap(campaignId, adId) {
        return dispatch => {
            dispatch({
                type: 'AD_FORM__REFRESH_GEO_ENTITY_IN_MAP',
                payload: { campaignId, adId },
            });
        };
    },

    poiSearchStringChanged(campaignId, adId, searchString) {
        return (dispatch, getState) => {
            const organizationId = _.get(getState(), `profile.organizationId`);
            const geoResources = _.get(getState(), `resources.geoCategories`);
            const geoCategories = {
                ...geoResources,
            };

            const geoCategories_searched = geoLayersSearch(searchString, geoCategories);

            geoCategories_searched.geoLayersForThisOrg = _.filter(
                geoCategories_searched.geoLayers,
                layer => layer.organization === organizationId
            );
            dispatch({
                type: 'AD_FORM__GEOLAYER_SEARCH_STRING_CHANGED',
                payload: { campaignId, adId, searchString, geoCategories: geoCategories_searched },
            });
        };
    },

    geoLayerFinder_refresh(campaignId, adId) {
        return (dispatch, getState) => {
            // poi module
            const geoResources = _.get(getState(), `resources.geoCategories`);
            const organizationId = _.get(getState(), `profile.organizationId`);
            const geoCategories = {
                ...geoResources,
                geoLayersForThisOrg: _.filter(
                    geoResources.geoLayers,
                    layer => layer.organization === organizationId
                ),
            };

            dispatch({
                type: 'AD_FORM__GEOLAYER_FINDER__REFRESH',
                payload: { campaignId, adId, geoCategories },
            });
        };
    },

    geoLayerFinder_folderStateChanged(campaignId, adId, folderState) {
        return (dispatch, getState) => {
            const toggledNodeId = _.get(folderState, 'toggledNodeId', void 0);
            const openFolderId = _.get(folderState, 'openFolderId', void 0);
            const geoCategories = _.get(getState(), `adForm.form.geoCategories`, void 0);

            if (toggledNodeId === 'customLayers') {
                if (
                    geoCategories.geoLayersForThisOrg.isOpen === void 0 ||
                    geoCategories.geoLayersForThisOrg.isOpen === false
                ) {
                    geoCategories.geoLayersForThisOrg.isOpen = true;
                } else if (geoCategories.geoLayersForThisOrg.isOpen === true) {
                    geoCategories.geoLayersForThisOrg.isOpen = false;
                }
            }

            if (toggledNodeId) {
                geoCategories.geoCategories.forEach(geoCategory => {
                    const subcategories = _.get(geoCategory, 'subcategories', []);

                    subcategories.forEach(subcategory => {
                        subcategory.parent = geoCategory;

                        if (subcategory.id === toggledNodeId) {
                            if (subcategory.isOpen === void 0 || subcategory.isOpen === false) {
                                subcategory.isOpen = true;
                            } else if (subcategory.isOpen === true) {
                                delete subcategory.isOpen;
                            }
                        }
                    });

                    if (geoCategory.id === toggledNodeId) {
                        if (geoCategory.isOpen === void 0 || geoCategory.isOpen === false) {
                            geoCategory.isOpen = true;
                        } else if (geoCategory.isOpen === true) {
                            delete geoCategory.isOpen;
                        }
                    }
                });
            }

            if (openFolderId) {
                geoCategories.geoCategories.forEach(geoCategory => {
                    const subcategories = _.get(geoCategory, 'subcategories', []);

                    subcategories.forEach(subcategory => {
                        subcategory.parent = geoCategory;

                        if (subcategory.id === openFolderId) {
                            subcategory.isOpen = true;
                        }
                    });

                    if (geoCategory.id === openFolderId) {
                        geoCategory.isOpen = true;
                    }
                });
            }

            dispatch({
                type: 'AD_FORM__GEOLAYER_FINDER__FOLDER_STATE_CHANGED',
                payload: { campaignId, adId, geoCategories: geoCategories },
            });
        };
    },

    switchCreativeMode(newMode, selectedCreatives) {
        return {
            type: 'AD_FORM__CREATIVES__SWITCH_MODE',
            payload: {
                newMode,
                selectedCreatives,
            },
        };
    },

    changeCreativeRotationWeight(creativeId, newWeight) {
        return {
            type: 'AD_FORM__CREATIVES__CHANGE_WEIGHT',
            payload: {
                creativeId,
                newWeight,
            },
        };
    },

    selectSingleCreative(creativeId) {
        return {
            type: 'AD_FORM__CREATIVES__SELECT_SINGLE_CREATIVE',
            payload: {
                creativeId,
            },
        };
    },

    selectEvenCreative(creativeIds) {
        return {
            type: 'AD_FORM__CREATIVES__SELECT_EVEN_CREATIVE',
            payload: {
                creativeIds,
            },
        };
    },

    selectWeightedCreative(creativeIds) {
        return {
            type: 'AD_FORM__CREATIVES__SELECT_WEIGHTED_CREATIVE',
            payload: {
                creativeIds,
            },
        };
    },

    addCreativeScheduleRow() {
        return {
            type: 'AD_FORM__CREATIVES__ADD_CREATIVE_SCHEDULE_ROW',
            payload: {},
        };
    },

    selectScheduledWeightedCreative({ dateRangeIndex, creativeIndex, creativeId }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__SELECT',
            payload: {
                dateRangeIndex,
                creativeIndex,
                creativeId,
            },
        };
    },

    removeDateRange(dateRangeIndex) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_DATE_RANGE',
            payload: {
                dateRangeIndex,
            },
        };
    },

    addScheduledWeightedCreativeDateRange() {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__ADD_DATE_RANGE',
            payload: {},
        };
    },

    removeMultiCreatives({ dateRangeIndex }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_CREATIVES',
            payload: {
                dateRangeIndex,
            },
        };
    },

    changeScheduledWeightedCreativeEndDate({ dateRangeIndex, end }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__CHANGE_END_DATE',
            payload: {
                dateRangeIndex,
                end,
            },
        };
    },

    removeScheduledWeightedCreative({ dateRangeIndex, creativeIndex }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__REMOVE_CREATIVE',
            payload: {
                dateRangeIndex,
                creativeIndex,
            },
        };
    },

    addScheduledWeightedCreative(dateRangeIndex) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__ADD_CREATIVE',
            payload: {
                dateRangeIndex,
            },
        };
    },

    changeScheduledWeightedCreativeWeight({ dateRangeIndex, creativeIndex, weight }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__CHANGE_WEIGHT',
            payload: {
                dateRangeIndex,
                creativeIndex,
                weight,
            },
        };
    },

    changeScheduledEvenWeighted({ dateRangeIndex }) {
        return {
            type: 'AD_FORM__CREATIVES__SCHEDULED_WEIGHTED_CREATIVE__EVEN_CHANGE_WEIGHT',
            payload: {
                dateRangeIndex,
            },
        };
    },

    updateCreativeScheduleRow(rowId, data, args) {
        return {
            type: 'AD_FORM__CREATIVES__UPDATE_CREATIVE_SCHEDULE_ROW',
            payload: {
                rowId,
                data,
                ...args,
            },
        };
    },

    removeCreativeScheduleRow(rowId) {
        return {
            type: 'AD_FORM__CREATIVES__REMOVE_CREATIVE_SCHEDULE_ROW',
            payload: {
                rowId,
            },
        };
    },

    clearCreatives() {
        return {
            type: 'AD_FORM__CREATIVES__CLEAR_CREATIVES',
            payload: {},
        };
    },
    updateFrequencyCapWarning() {
        return {
            type: 'AD_FORM__FREQUENCY_CAP__UPDATE_WARNING',
            payload: {},
        };
    },
    updateFtaLineId(lineId) {
        return {
            type: 'AD_FORM__FTA__UPDATE_LINE_ID',
            payload: {
                lineId,
            },
        };
    },
    updateFtaPartnerId(partnerId) {
        return {
            type: 'AD_FORM__FTA__UPDATE_PARTNER_ID',
            payload: {
                partnerId,
            },
        };
    },
    changePlatform(platform) {
        return {
            type: 'AD_FORM__PLATFORM__CHANGE',
            payload: {
                platform,
            },
        };
    },
    changePlatforms({ value, checked }) {
        return {
            type: 'AD_FORM__PLATFORMS__CHANGE',
            payload: {
                value,
                checked,
            },
        };
    },
    changeIASViewability(code) {
        return {
            type: 'AD_FORM__IAS_VIEWABILITY__CHANGE',
            payload: {
                code,
            },
        };
    },
    changeBrandSafety(category, code) {
        return {
            type: 'AD_FORM__IAS_BRAND_SAFETY__CHANGE',
            payload: {
                category,
                code,
            },
        };
    },
    changeContextualTargeting(values) {
        return {
            type: 'AD_FORM__IAS_CONTEXTUAL_TARGETING__CHANGE',
            payload: {
                values,
            },
        };
    },
    changeGroupmViewability(value) {
        return {
            type: 'AD_FORM__IAS_GROUPM_VIEWABILITY__CHANGE',
            payload: {
                value,
            },
        };
    },
    changePublicisViewability(value) {
        return {
            type: 'AD_FORM__IAS_PUBLICIS_VIEWABILITY__CHANGE',
            payload: {
                value,
            },
        };
    },
    addPanel(panel) {
        return {
            type: 'AD_FORM__ADD_PANEL',
            payload: {
                panel,
            },
        };
    },
    cancelSave() {
        return {
            type: 'AD_FORM__CANCEL_SAVE',
            payload: {},
        };
    },
};

export const getHardSeverityTacticWarnings = ({ tacticWarnings }) => {
    const processedWarnings = _.map(Object.keys(tacticWarnings), tacticSectionId => {
        const tacticSectionModules = tacticWarnings[tacticSectionId].modules;
        const moduleWarnings = _(tacticSectionModules)
            .map(moduleId => {
                const moduleWarningDetails = tacticWarnings[tacticSectionId][moduleId];
                const audienceSegmentWarning = moduleWarningDetails.audience_segments;
                if (
                    audienceSegmentWarning.hasWarning &&
                    audienceSegmentWarning.severity === 'hard'
                ) {
                    const processedModuleWarning = {
                        title: 'Audience Segments',
                        segmentNames: audienceSegmentWarning.lowReachSegmentNames,
                        id: moduleId,
                    };
                    return processedModuleWarning;
                }
            })
            .filter(warning => warning)
            .value();

        if (moduleWarnings.length > 0) {
            const tacticWarning = {
                title: tacticWarnings[tacticSectionId].title,
                key: tacticSectionId,
                moduleWarnings: moduleWarnings,
            };

            return tacticWarning;
        }
    });

    return processedWarnings.filter(warning => warning);
};

async function fetchAdFormData(campaignId, orgId) {
    try {
        const http = createHttp();
        const query = `
            query getAdFormData ($keywords: String, $source_type: [String], $campaignId: Int, $orgId: String) {
                audienceSegments(filters: { keywords: $keywords, source_type: $source_type }) {
                    id
                    name
                    source_type
                    upload_origin
                    third_party_fees
                    supports
                }
                campaign(id: $campaignId) {
                    budget_allocation_method
                    isCrossPlatformCampaign
                    type
                    clients{
                        organization,
                        type
                    }
                    creatives {
                        id,
                        name,
                        format,
                        platforms
                    }
                    carriersISPsVersion
                    conversions {
                        event_name
                        reporting_name
                    }
                    tech_fee
                }
                applists(filters: {}) {
                    id
                    name
                    advertiser {
                        id
                        name
                    }
                }
                organization(id: $orgId) {
                    name
                    tech_fee
                    blacklistedAppsAndSites
                    fta_partner_id
                    audience_rates {
                        name
                        fee
                    }
                    isIasPostBidVerificationEnabled
                }
                organizations(filters: {relationship: "child", type: ["client", "co_managed_client"]}) {
                    id
                    name
                    client_type
                    blacklistedAppsAndSites
                    whitelistedAppsAndSites
                }
                deals {
                    id
                    app_name
                    currency
                    price_local
                    category
                    start_date
                    end_date
                    advertiser {
                        id
                        name
                    }
                }
                ftaLocationLists {
                    id
                    name
                }
                flexSegments(filters: { source_type: ["flex_standard_brand", "flex_custom_brand", "flex_location_category"] }) {
                    id
                    value: id
                    label: name
                    name
                    source_type
                    source_type_category
                    points
                    supports
                }
            }
        `;

        const variables = {
            keywords: null,
            source_type: null,
            campaignId,
            orgId,
        };

        const data = await http.graphql(query, variables);

        return {
            ...data,
            dealsByOrganization: data.deals,
        };
    } catch (err) {
        console.error(err);

        return [];
    }
}

export default AdNew;
