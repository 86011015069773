import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Hidden from '@mui/material/Hidden';
import { AccordionDetails } from '@mui/material';

const Accordion = withStyles({
    root: {
        '&$disabled': {
            backgroundColor: '#eaeaea',
            cursor: 'not-allowed',
        },
        '&$expanded': {
            '&:hover > div': {
                cursor: 'default',
            },
        },
    },
    disabled: {},
    expanded: {},
})(MuiAccordion);

const useStyles = makeStyles(() => ({
    panel: {
        display: 'flex',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    title: {
        flex: 1.3,
    },
    body: {
        flex: 4,
        maxWidth: '75%',
    },
    description: {
        marginTop: 10,
        marginRight: 10,
        flex: 2,
    },
    expansionSummary: {
        alignItems: 'flex-start',
    },
    expansionSummaryContent: {
        width: '90%',
    },
    expansionSummaryReadOnly: {
        cursor: 'default',
    },
    red: {
        color: 'red',
    },
    focused: {
        backgroundColor: 'white !important',
    },
    readOnly: {
        visibility: 'hidden',
    },
    expandedSummary: {
        cursor: 'default',
    },
    iconButton: {
        cursor: 'pointer',
    },
}));

function AccordionItem(props) {
    const classes = useStyles();

    return (
        <Accordion
            expanded={props.expanded}
            disabled={props.disabled}
            onChange={props.readOnly || props.expanded ? _.noop : props.onClick}
            className={props.className}
        >
            <AccordionSummary
                expandIcon={
                    <div
                        className={`${classes.iconButton} ${
                            props.readOnly ? classes.readOnly : ''
                        }`}
                    >
                        <ExpandMoreIcon fontSize="large" onClick={props.onClick} />
                    </div>
                }
                IconButtonProps={{
                    disabled: props.readOnly,
                    onClick: props.onClick,
                    classes: { root: classes.iconButton },
                }}
                classes={{
                    content: (props.readOnly && classes.expansionSummaryReadOnly,
                    classes.expansionSummaryContent),
                    root: classes.expansionSummary,
                    focused: classes.focused,
                    expanded: classes.expandedSummary,
                }}
            >
                {props.mdHidden ? (
                    <div className={classes.panel}>
                        <Hidden lgDown>
                            <FormControl className={classes.title} error={!!props.error}>
                                <FormLabel
                                    required={props.required}
                                    classes={{
                                        asterisk:
                                            props.error || props.incomplete ? classes.red : null,
                                    }}
                                >
                                    {props.title}
                                </FormLabel>
                                {props.helper && props.expanded && (
                                    <div className={classes.description}>
                                        <Typography color="textSecondary" variant="body2">
                                            {props.helper}
                                        </Typography>
                                    </div>
                                )}
                            </FormControl>
                        </Hidden>
                        <div className={classes.body}>
                            {props.expanded ? (
                                <React.Fragment>
                                    <div className={classes.details}>{props.details}</div>
                                </React.Fragment>
                            ) : (
                                <div>{props.summary}</div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={classes.panel}>
                        <FormControl className={classes.title} error={!!props.error}>
                            <FormLabel
                                required={props.required}
                                classes={{
                                    asterisk: props.error || props.incomplete ? classes.red : null,
                                }}
                            >
                                {props.title}
                            </FormLabel>
                            {props.helper && props.expanded && (
                                <div className={classes.description}>
                                    <Typography color="textSecondary" variant="body2">
                                        {props.helper}
                                    </Typography>
                                    {props.externalLink && (
                                        <Typography color="textSecondary" variant="body2">
                                            <a
                                                target="_blank"
                                                href={props.externalLink}
                                                rel="noreferrer"
                                            >
                                                Learn more
                                            </a>
                                        </Typography>
                                    )}
                                </div>
                            )}
                        </FormControl>
                        <div className={classes.body}>
                            {props.expanded ? props.details : props.summary}
                        </div>
                    </div>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.fullWidth}>{props.extension}</div>
            </AccordionDetails>
        </Accordion>
    );
}

export default AccordionItem;
