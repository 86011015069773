import _ from 'lodash';
import { can } from 'services/can';
import { PermissionsMapping } from 'common/constants/role-intents';
import { userBelongsTo } from 'states/profile/business-rules';
import { DealCategory } from '../../../../common/constants/deals';

export default function selector(storeState) {
    const { loading, deals, keywords, sort, timezone, filters, advertisers } = _.get(
        storeState,
        'dealsTable'
    );
    const profileState = _.get(storeState, 'profile');

    const isCurated = location.pathname.split('/').pop() === 'curated-deals';
    const hasPermissionToEditCuratedDeals =
        userBelongsTo(profileState, ['addictive mobility', 'root']) &&
        can(PermissionsMapping.AUDITS__VIEW_AND_EDIT, profileState.globalRole);

    const advertiserOptions = _.map(advertisers, advertiser => {
        return { label: advertiser.name, value: advertiser.id };
    });

    const filterDeals = deals.filter(deal => {
        if (isCurated) {
            return deal.category === DealCategory.Curated;
        } else {
            return deal.category === DealCategory.Private;
        }
    });

    return {
        loading,
        deals: filterDeals,
        keywords,
        sort,
        timezone,
        filters,
        advertiserOptions,
        isCurated,
        disableEdit: isCurated && !hasPermissionToEditCuratedDeals,
    };
}
